export const fusionauthURL = "https://openlaw.fusionauth.io";
export const JADEAuthURL = "https://jade.io";
// export const JADEAuthURL = "https://jda.openlaw.org.au:5120";

export const JASMINE_ENDPOINT: string = process.env.NEXT_PUBLIC_API ?? "http://localhost:8080";

// export const JASMINE_ENDPOINT: string =
//     JSON.stringify(process.env.NEXT_PUBLIC_NON_PRODUCTION) === JSON.stringify("true")
//         ? "https://np-jasmine-api.jade.io"
//         : JSON.stringify(process.env.NEXT_PUBLIC_PRODUCTION) === JSON.stringify("true")
//         ? "https://jasmine-api.jade.io"
//         : "http://localhost:8080";

export const Honourifics = [
    "ACJ",
    "AJA",
    "AJJA",
    "AJ",
    "AJJ",
    "AP",
    "AsJ",
    "AUJ",
    "CJA",
    "CJ at CL",
    "CJ, Cl",
    "CJ in Eq",
    "CJ Comm D",
    "CJ",
    "Commissioner",
    "DCJ",
    "FM",
    "Judge",
    "JR",
    "JA",
    "JJA",
    "J",
    "JJ",
    "Magistrate",
    "Master",
    "P",
    "SJA",
    "SJ",
    "SJJ",
    "SPJ",
    "V-P",
];

export const Suggestions = [
    "When can the corporate veil be pierced?",
    "When is coincidence evidence admissible?",
    "What is the business records exception to hearsay?",
    "What are the indicia of a fiduciary relationship?",
    "What does it mean to postpone a prior equity?",
    "When is a restraint of trade clause enforceable?",
    "When is a contractual term a penalty?",
    "When does a contractual term survive termination by repudiation?",
    "What is the difference between irrationality and unreasonableness in administrative law?",
    "Is an unlawful administrative decision void or voidable?",
    "What is the difference between a court and a tribunal?",
];

export const TextClass = "text-base font-sourceSansPro max-w-prose text-left subpixel-antialiased";

export const MNC2Jurisdiction = {
    HCA: "High Court of Australia",
    UKPCHCA: "Privy Council - Appeals from the High Court of Australia",
    HCAB: "High Court of Australia - Bulletins",
    HCASL: "High Court of Australia - Special Leave Dispositions",
    HCATrans: "High Court of Australia - Transcripts",
    FCAFC: "Federal Court of Australia - Full Court",
    FamCAFC: "Family Court of Australia - Full Court",
    FedCFamC1A: "Federal Circuit and Family Court of Australia - Division 1 Appellate Jurisdiction",
    ACTCA: "Supreme Court of the Australian Capital Territory - Court of Appeal",
    NSWCA: "Supreme Court of New South Wales - Court of Appeal",
    NSWCCA: "Supreme Court of New South Wales - Court of Criminal Appeal",
    NTCA: "Supreme Court of the Northern Territory - Court of Appeal",
    NTCCA: "Supreme Court of the Northern Territory - Court of Criminal Appeal",
    NTSCFC: "Supreme Court of the Northern Territory - Full Court",
    QCA: "Supreme Court of Queensland - Court of Appeal",
    SASCA: "Supreme Court of South Australia - Court of Appeal",
    SASCFC: "Supreme Court of South Australia - Full Court",
    TASCCA: "Supreme Court of Tasmania - Court of Criminal Appeal",
    TASFC: "Supreme Court of Tasmania - Full Court",
    VSCA: "Supreme Court of Victoria - Court of Appeal",
    WASCA: "Supreme Court of Western Australia - Court of Appeal",
    FCA: "Federal Court of Australia",
    FamCA: "Family Court of Australia",
    FedCFamC1F: "Federal Circuit and Family Court of Australia - Division 1 First Instance",
    NFSC: "Supreme Court of Norfolk Island",
    FMCA: "Federal Magistrates Court of Australia",
    FCCA: "Federal Circuit Court of Australia",
    FMCAfam: "Federal Magistrates Court of Australia - Family Law",
    FedCFamC2F: "Federal Circuit and Family Court of Australia - Division 2 Family Law",
    FedCFamC2G: "Federal Circuit and Family Court of Australia - Division 2 General Federal Law",
    IRCA: "Industrial Relations Court of Australia",
    AATA: "Administrative Appeals Tribunal of Australia",
    NNTTA: "National Native Title Tribunal",
    AFPDT: "Australian Federal Police Disciplinary Tribunal",
    ATPT: "Australian Trade Practices Tribunal",
    SCTA: "Superannuation Complaints Tribunal of Australia",
    ACompT: "Australian Competition Tribunal",
    ACopyT: "Copyright Tribunal",
    ADFDAT: "Defence Force Discipline Appeal Tribunal",
    ADO: "Australian Designs Office",
    APO: "Australian Patent Office",
    ATMO: "Australian Trade Marks Office",
    ATMOGI: "Australian Trade Marks Office Geographical Indication",
    FWAFB: "Fair Work Australia - Full Bench",
    FWCFB: "Fair Work Commission - Full Bench",
    FWA: "Fair Work Australia",
    FWAA: "Fair Work Australia - Enterprise Agreement",
    FWC: "Fair Work Commission",
    FWCA: "Fair Work Commission - Enterprise Agreement",
    FWCD: "Fair Work Commission - General Manager and Delegates",
    ACTSC: "Supreme Court of the Australian Capital Territory",
    NSWSC: "Supreme Court of New South Wales",
    NTSC: "Supreme Court of Northern Territory",
    QSC: "Supreme Court of Queensland",
    SASC: "Supreme Court of South Australia",
    TASSC: "Supreme Court of Tasmania",
    VSC: "Supreme Court of Victoria",
    WASC: "Supreme Court of Western Australia",
    ACTSCFC: "Supreme Court of the Australian Capital Territory - Full Court",
    ACTCD: "ACT Coroner's Court",
    ACTIC: "ACT Industrial Court",
    ACTMC: "ACT Magistrates Court",
    ACAT: "ACT Civil and Administrative Tribunal",
    ACTDT: "Discrimination Tribunal of the Australian Capital Territory",
    ACTAAT: "Administrative Appeals Tribunal of the Australian Capital Territory",
    ACTRTT: "Residential Tenancies Tribunal of the Australian Capital Territory",
    NSWLEC: "Land and Environment Court of New South Wales",
    NSWDC: "District Court of New South Wales",
    NSWIC: "Industrial Court of New South Wales",
    NSWIRComm: "Industrial Relations Commission of New South Wales",
    "NSW  NSWPIC": "Personal Injury Commission",
    "NSW  - Medical Appeal Panel NSWPICMP": "Personal Injury Commission",
    "NSW  - Merit Review NSWPICMR": "Personal Injury Commission",
    "NSW  - Merit Review Appeal Panel NSWPICMRA": "Personal Injury Commission",
    "NSW  - Merit Review Panel NSWPICMRP": "Personal Injury Commission",
    "NSW  - Presidential Decisions NSWPICPD": "Personal Injury Commission",
    NSWWCCPD: "Workers Compensation Commission - Presidential Decisions",
    NSWLC: "Local Court New South Wales",
    NSWCATAP: "Civil and Administrative Tribunal New South Wales - Appeal Panel",
    NSWCTTT: "Consumer, Trader and Tenancy Tribunal of New South Wales",
    NSWCATAD: "Civil and Administrative Tribunal New South Wales - Administrative and Equal Opportunity Division",
    NSWCATCD: "Civil and Administrative Tribunal New South Wales - Consumer and Commercial Division",
    NSWCATGD: "Civil and Administrative Tribunal New South Wales - Guardianship Division",
    NSWCATOD: "Civil and Administrative Tribunal New South Wales - Occupational Division",
    NSWMT: "Medical Tribunal New South Wales",
    NSWADTAP: "Administrative Decisions Tribunal of New South Wales - Appeal Panel",
    NSWDDT: "Dust Diseases Tribunal of New South Wales",
    NSWADT: "Administrative Decisions Tribunal of New South Wales",
    NTLC: "Northern Territory Local Court",
    NTMC: "Northern Territory Magistrates Court",
    QSCPR: "Supreme Court of Queensland - Pretrial Rulings",
    ICQ: "Industrial Court Of Queensland",
    QChC: "Children's Court Of Queensland",
    QChCM: "Magistrates Court Of Queensland - Children's Court",
    QDC: "District Court of Queensland",
    QDCPR: "District Court of Queensland - Pretrial Rulings",
    QIRC: "Queensland Industrial Relations Commission",
    QLAC: "Queensland Land Court - Appeal Cases",
    QLC: "Queensland Land Court",
    QMC: "Queensland Magistrates Court",
    QMHC: "Queensland Mental Health Court",
    QPEC: "Planning and Environment Court of Queensland",
    QCATA: "Queensland Civil and Administrative Tribunal - Appeals Division",
    QCAT: "Queensland Civil and Administrative Tribunal",
    SADC: "District Court of South Australia",
    SAWC: "Wardens Court of South Australia",
    SAERDC: "Environment Resources and Development Court of South Australia",
    SACAT: "South Australian Civil and Administrative Tribunal",
    SAEOT: "Equal Opportunity Tribunal of South Australia",
    TASCAT: "Tasmanian Civil and Administrative Tribunal",
    TASGAB: "Tasmanian Guardianship and Administration Board",
    VCC: "County Court of Victoria",
    VMC: "Magistrates' Court of Victoria",
    VCAT: "Victorian Civil and Administrative Tribunal",
    FCWA: "Family Court of Western Australia",
    FCWAM: "Family Court of Western Australia, Magistrates",
    WAMW: "Warden's Court of Western Australia",
    WADC: "District Court of Western Australia",
    WASAT: "State Administrative Tribunal of Western Australia",
    "UK  UKSC": "Supreme Court",
    UKHL: "House of Lords",
    NZSC: "Supreme Court of New Zealand",
    NZCA: "Court of Appeal of New Zealand",
    NZHC: "High Court of New Zealand",
    AULegOther: "Commonwealth Legislation - Other",
    AULegAct: "Commonwealth Legislation - Acts",
    AULegSR: "Commonwealth Legislation - Statutory Rules",
    NSWLegAct: "NSW Legislation - Acts",
    NSWLegSI: "NSW Legislation - Rules",
    NSWLegEPI: "NSW Legislation - Environmental Planning Instruments",
    VICLegAct: "Victorian Legislation - Acts",
    VICLegSI: "Victorian Legislation - Statutory Instruments",
    QLDLegAct: "Queensland Legislation - Acts",
    QLDLegSI: "Queensland Legislation - Statutory Instruments",
    SALegAct: "South Australian Legislation - Acts",
    SALegSI: "South Australian Legislation - Statutory Instruments",
    ACTLegAct: "ACT Legislation - Acts",
    ACTLegSI: "ACT Legislation - Statutory Instruments",
    WALegAct: "Western Australian Legislation - Acts",
    WALegSI: "Western Australian Legislation - Statutory Instruments",
    NTLegAct: "Northern Territory Legislation - Acts",
    NTLegSI: "Northern Territory Legislation - Statutory Instruments",
    TASLegAct: "Tasmanian Legislation - Acts",
    TASLegSI: "Tasmanian Legislation - Statutory Instruments",
};
