import React, { FunctionComponent, PropsWithChildren } from "react"
import JasmineFooter from "../shared/JasmineFooter"
import PageLayout from "./PageLayout"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["jasmine-page-layout"]: unknown;
        }
    }
}

interface JasminePageLayoutProps {
    path: string;
    authenticated: boolean;
}

export const JasminePageLayout: FunctionComponent<PropsWithChildren<JasminePageLayoutProps>> = (props) => {
    const { children } = props
    return (
        <jasmine-page-layout is='div'>
            <PageLayout
                authenticated={props.authenticated}
                headerProps={{ logo: "/logos/jasmine-light.svg" }}
                className=''
                footer={<JasmineFooter />}
                path={props.path}
            >
                {children}
            </PageLayout>
        </jasmine-page-layout>
    )
}

export default JasminePageLayout
