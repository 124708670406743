import Link from "next/link"
import { FunctionComponent } from "react"

interface FooterProps {}

const SiteFooter: FunctionComponent<FooterProps> = () => {
    return (
        <footer className={`mt-12 bg-[#f4f9f8]`}>
            <div className={`max-w-7xl mx-auto`}>
                <div className={`mx-8 relative`}>
                    <div className={`absolute inset-0 flex items-center`}>
                        <div className={`w-full border-t border-gray-300`} />
                    </div>
                </div>
                <div className={`mx-16 flex flex-col lg:flex-row items-center justify-between`}>
                    <div className={`ml-0 flex items-center leading-none lg:leading-loose`}>
                        <Link
                            href='https://jade.io/j/?c=terms+of+use'
                            target='_blank'
                            rel={"noreferrer"}
                            className={`m-4 text-xs lg:text-base text-green-600 hover:text-green-500 hover:underline hover:decoration-2 font-plexSans antialiased`}
                        >
                            Terms of use
                        </Link>
                        <span className={`md:m-4`}>&middot;</span>
                        <Link
                            href='https://jade.io/j/?c=privacy+statement'
                            target='_blank'
                            rel={"noreferrer"}
                            className={`m-4 text-xs lg:text-base text-green-600 hover:text-green-500 hover:underline hover:decoration-2 font-plexSans antialiased`}
                        >
                            Privacy policy
                        </Link>
                    </div>
                    <div className={`ml-0 flex flex-col lg:flex-row items-center leading-none lg:leading-loose`}>
                        <p
                            className={`m-0 lg:m-4 leading-none lg:leading-loose text-xs lg:text-base font-plexSans antialiased`}
                        >
                            Version 0.2.0
                        </p>
                        <span className={`invisible lg:visible m-0 lg:m-4`}>&middot;</span>
                        <p
                            className={`mb-4 lg:m-4 leading-none lg:leading-loose text-xs lg:text-base font-plexSans antialiased`}
                        >
                            &copy; 2000 - 2024 BarNetwork Pty Limited (ABN 32 092 121 198). All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default SiteFooter
