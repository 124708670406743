import ax from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export default class HTTPClient {
    url: string;

    constructor(endpoint: string) {
        this.url = endpoint;
    }

    get = (route: string, headers: any = {}) => {
        return ax({
            method: "GET",
            url: this.url + route,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...headers,
            },
            withCredentials: true,
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

    post = <T>(route: string, body: any = {}, headers: any = {}) => {
        return ax<T>({
            method: "POST",
            url: this.url + route,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...headers,
            },
            data: JSON.stringify(body),
            withCredentials: true,
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

    stream = (route: string, body: any = {}, handleStreamUpdate: (s: string) => void) => {
        return fetchEventSource(this.url + route, {
            method: "POST",
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
            credentials: "include",
            onmessage(ev) {
                handleStreamUpdate(ev.data);
            },
        });
    };
}
